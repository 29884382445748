import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLongArrowAltRight,
  faDollarSign,
  faCashRegister,
  faExclamationCircle,
  faMoneyBillAlt as fasMoneyBillAlt,
  faCheck,
  faCheckCircle,
  faQuestionCircle,
  faQuestion,
  faDatabase,
  faRetweet,
  faCode,
  faEdit,
  faCloud,
  faLayerGroup,
  faCalendar,
  faRss,
  faFile,
  faVideo,
  faComments,
  faPaperPlane,
  faTimes,
  faTimesCircle,
  faCogs,
  faTachometerAlt,
  faPuzzlePiece,
  faSmile,
  faInfo,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import {
  faLightbulb,
  faCreditCard,
  faMoneyBillAlt as farMoneyBillAlt
} from "@fortawesome/free-regular-svg-icons";

import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import 'bootstrap/dist/css/bootstrap.min.css';
// general js and styles
import "./src/styles/base.css";
import "typeface-lato";

import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import googleAnalytics from '@analytics/google-analytics';

import { REACT_APP_GTM_NAME, REACT_APP_GTM_CONTAINER_ID, REACT_APP_MEASUREMENT_ID_1 } from 'gatsby-env-variables';

// Configure here the font-awesome icons you'll be using across the website
library.add(
  faLongArrowAltRight,
  faDollarSign,
  faCashRegister,
  faExclamationCircle,
  farMoneyBillAlt,
  faCheck,
  faCheckCircle,
  faQuestionCircle,
  faQuestion,

  faCreditCard,
  faLightbulb,
  fasMoneyBillAlt,

  faFacebook,
  faTwitter,
  faLinkedin,

  faDatabase,
  faRetweet,
  faCode,
  faEdit,
  faLayerGroup,
  faCloud,
  faCalendar,
  faRss,
  faFile,
  faVideo,
  faComments,
  faPaperPlane,
  faTimes,
  faTimesCircle,
  faCogs,
  faTachometerAlt,
  faPuzzlePiece,
  faSmile,

  faInfo,
  faInfoCircle,
);

console.log(REACT_APP_GTM_NAME, REACT_APP_GTM_CONTAINER_ID, REACT_APP_MEASUREMENT_ID_1);

if (REACT_APP_GTM_NAME) {
  const analytics = Analytics({
    app: REACT_APP_GTM_NAME,
    plugins: [
      googleTagManager({
        containerId: REACT_APP_GTM_CONTAINER_ID
      }),
      googleAnalytics({
        measurementIds: [REACT_APP_MEASUREMENT_ID_1]
      })
    ]
  });
  
  if (typeof window !== undefined) {
    window.analytics = analytics;
  }
}
